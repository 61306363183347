import { Flex, Link, VStack, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { COPPA_URL } from "links/lib/constants";

import BackgroundPNG from "../resource/sign-in-background.png";

const AnimatedFlex = animated(Flex);

interface IProps {
  handleSignIn: () => void;
}

export const SignInCard: React.FC<IProps> = ({ handleSignIn }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const spring = useSpring({
    from: {
      bottom: -60,
    },
    to: {
      bottom: 0,
    },
    config: config.wobbly,
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <AnimatedFlex
      style={spring}
      w="full"
      position="fixed"
      bottom="0"
      left={isMobile ? "0" : undefined}
      borderRadius="xl"
      padding={isMobile ? pxToRem(12) : pxToRem(48)}
      bgImage={isMobile ? undefined : BackgroundPNG}
      bgColor={isMobile ? "primary.tan" : undefined}
      bgRepeat="no-repeat"
    >
      <VStack
        spacing={pxToRem(16)}
        alignItems={isMobile ? "center" : "start"}
        maxW={isMobile ? "full" : "70%"}
      >
        <Heading as="h4" variant="adminH6Bold">
          {t("guestTeacherCreateInstantSet.signInCardHeading")}
        </Heading>
        <Button
          w={isMobile ? undefined : "30%"}
          bgColor="primary.white"
          variant="adminButtonOutlined"
          onClick={handleSignIn}
        >
          {t("common.signIn")}
        </Button>
        <Text variant="adminP2">
          <Trans i18nKey="guestTeacherCreateInstantSet.signInCardWarning" t={t}>
            <Link target="_blank" color="utility.link" href={COPPA_URL}>
              COPPA Disclosure
            </Link>
          </Trans>
        </Text>
      </VStack>
    </AnimatedFlex>
  );
};
