import {
  Box,
  Flex,
  HStack,
  Spacer,
  Stack,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Icon } from "adminComponents/atoms/Icon";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Tag } from "adminComponents/atoms/Tag";
import {
  chakraStylesGradeDropdown,
  chakraStylesSubjectDropdown,
} from "adminComponents/theme/components/Dropdown";
import { pxToRem } from "adminComponents/utils";
import { ISearchQuery } from "lib/hooks/useLibraryFilter";
import { subjectIdToIconDataMap } from "links/lib/constants";
import { IStandard, ISubject } from "links/lib/types";
import { IGuestNavigationDataContext } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";
import { sortGradeLevelsTwoColumns } from "sharedComponents/utils/sortGradeLevelsTwoColumns";

import { INavigationHeaderProps } from "../NavigationHeader";
import { dropdownWidth } from "../TeacherNavigationHeader";

interface IProps {
  navigationData: IGuestNavigationDataContext | INavigationHeaderProps;
  isLoading: boolean;
  query: ISearchQuery;
  totalQueryCount: number;
  standards: IStandard[];
  selectedParentSubject?: ISubject;
  handleSelectSubjects: (subjectIds: string[]) => void;
  handleSelectGrades: (gradeLevelId: string[]) => void;
  handleRemoveFilterTag: (
    category: "subject" | "grade" | "standard",
    id: string
  ) => void;
  handleClearAllFilters: () => void;
  handleOpenFilterFlyout: () => void;
}

export const SearchTagsAndOptions: React.FC<IProps> = ({
  navigationData,
  isLoading,
  query,
  totalQueryCount,
  standards,
  selectedParentSubject,
  handleClearAllFilters,
  handleRemoveFilterTag,
  handleSelectSubjects,
  handleSelectGrades,
  handleOpenFilterFlyout,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const buttonSize = useBreakpointValue({ base: "xs", sm: "xs", md: "md" });

  const subjectOptions = useMemo(
    () =>
      navigationData.parentSubjects.map((s) => ({
        label: s.name,
        value: s.id,
      })),
    [navigationData.parentSubjects]
  );

  const gradeLevelOptions = useMemo(() => {
    return sortGradeLevelsTwoColumns(navigationData.gradeLevels).map((g) => ({
      label: g.grade_level,
      value: g.id,
    }));
  }, [navigationData.gradeLevels]);

  const largeOrLarger = useBreakpointValue({ base: false, lg: true });

  const renderTag = useCallback(
    (category: "subject" | "grade" | "standard", id: string, label: string) => (
      <Tag
        colorScheme="primary.white"
        handleClickRightIcon={() => handleRemoveFilterTag?.(category, id)}
        key={id}
        rightIcon="close"
      >
        {label}
      </Tag>
    ),
    [handleRemoveFilterTag]
  );

  const selectedSubjects = (query.filters.subjectIds || []).flatMap(
    (subjectId) =>
      navigationData.subjects.find((subject) => subject.id === subjectId) || []
  );

  const selectedSubjectsByParent: { [key: string]: boolean } = {};
  selectedSubjects.forEach((subject) => {
    if (subject.parent_id !== "0") {
      selectedSubjectsByParent[subject.parent_id] = true;
    } else {
      selectedSubjectsByParent[subject.id] = true;
    }
  });

  // Only show parent tags for subject if present
  const subjectTags = useMemo(() => {
    const parentIdMap: { [key: string]: true } = {};
    selectedSubjects.forEach((subject) => {
      if (subject && subject.parent_id === "0") {
        parentIdMap[subject.id] = true;
      }
    });

    return selectedSubjects
      .filter((subject) => {
        return !!subject && !parentIdMap[subject.parent_id];
      })
      .map((subject) => {
        return subject ? renderTag("subject", subject.id, subject.name) : null;
      });
  }, [selectedSubjects, renderTag]);

  const haveSelectedSubjectIds = !!query.filters.subjectIds?.length;
  const haveSelectedGradeLevelIds = !!query.filters.gradeLevelIds?.length;
  const haveSelectedStandardIds = !!query.filters.standardIds?.length;

  const gradeLevelTags = useMemo(() => {
    if (!haveSelectedGradeLevelIds) return null;

    return query.filters.gradeLevelIds?.map((gradeLevelId) =>
      renderTag(
        "grade",
        gradeLevelId,
        gradeLevelOptions.find(
          (option: IOption) => option.value === gradeLevelId
        )?.label || ""
      )
    );
  }, [
    gradeLevelOptions,
    haveSelectedGradeLevelIds,
    query.filters.gradeLevelIds,
    renderTag,
  ]);

  const standardTags = useMemo(() => {
    if (!haveSelectedStandardIds) return null;

    return query.filters.standardIds?.map((standardId) => {
      const standard = standards.find((standard) => standard.id === standardId);
      return renderTag(
        "standard",
        standardId,
        standard?.root_label || standard?.label || ""
      );
    });
  }, [
    haveSelectedStandardIds,
    query.filters.standardIds,
    renderTag,
    standards,
  ]);

  const selectedSubjectIconData = selectedParentSubject
    ? subjectIdToIconDataMap[selectedParentSubject.id]
    : undefined;
  const showSubjectHeading = !query?.term && !!selectedParentSubject;

  return (
    <Stack
      w="full"
      direction={largeOrLarger ? "row" : "column"}
      paddingTop={pxToRem(25)}
    >
      <>
        <VStack alignItems="flex-start">
          {query.term && (
            <HStack>
              <ClampedHeading headingVariant="adminH4" headingAs="h4">
                {isLoading
                  ? t("librarySearchResults.searchingFor", {
                      queryTerm: query?.term,
                    })
                  : t("librarySearchResults.queryResults", {
                      queryTerm: query?.term,
                      count: totalQueryCount,
                    })}
              </ClampedHeading>
              {isLoading && <LoadingSpinner size="md" />}
            </HStack>
          )}
          {showSubjectHeading && (
            <HStack>
              {selectedSubjectIconData && (
                <CoverImageIcon
                  borderColor={selectedSubjectIconData.color}
                  rgbBackgroundColor={selectedSubjectIconData.color}
                  icon={selectedSubjectIconData.coverImageIcon}
                  h={pxToRem(36)}
                  w={pxToRem(36)}
                  variant="adminSolidBorder"
                />
              )}
              <ClampedHeading headingVariant="adminH4" headingAs="h4">
                {selectedParentSubject.name}
              </ClampedHeading>
              {handleRemoveFilterTag && (
                <Icon
                  cursor="pointer"
                  icon="close"
                  onClick={() =>
                    handleRemoveFilterTag("subject", selectedParentSubject.id)
                  }
                />
              )}
              {isLoading && <LoadingSpinner size="md" />}
            </HStack>
          )}
          <Flex gap={pxToRem(10)} flexWrap="wrap">
            {!haveSelectedSubjectIds &&
              !haveSelectedGradeLevelIds &&
              !haveSelectedStandardIds && (
                // to prevent shifting of elements when no tags
                <Tag visibility="hidden">{t("common.clearAll")}</Tag>
              )}

            {standardTags}
            {gradeLevelTags}
            {haveSelectedSubjectIds && !showSubjectHeading && subjectTags}
            {(haveSelectedSubjectIds ||
              haveSelectedGradeLevelIds ||
              haveSelectedStandardIds) &&
              // Hide the clear all tag if it's only the single subject
              !(
                showSubjectHeading &&
                !haveSelectedGradeLevelIds &&
                !haveSelectedStandardIds
              ) && (
                <Button
                  onClick={handleClearAllFilters}
                  variant="adminTextButtonLarge"
                >
                  {t("common.clearAll")}
                </Button>
              )}
          </Flex>
        </VStack>
      </>
      <>
        <Spacer />
        <Stack
          direction={largeOrLarger ? "row" : "column"}
          alignItems={largeOrLarger ? "center" : "flex-start"}
        >
          {!showSubjectHeading && (
            <HStack>
              <Box minW={pxToRem(dropdownWidth)} w={pxToRem(dropdownWidth)}>
                <Dropdown
                  disabled={isLoading}
                  isMulti
                  chakraStyles={chakraStylesSubjectDropdown({
                    dropdownWidth,
                    roundButtonStyle: true,
                  })}
                  isSearchable={false}
                  placeholder={t("common.subjects")}
                  options={subjectOptions}
                  handleChange={(subjects) => {
                    handleSelectSubjects(
                      (subjects as IOption[]).map((subject) => subject.value)
                    );
                  }}
                  value={subjectOptions.filter((subject) =>
                    query.filters.subjectIds?.includes(subject.value)
                  )}
                  controlShouldRenderValue={false}
                />
              </Box>
              <Box minW={pxToRem(dropdownWidth)} w={pxToRem(dropdownWidth)}>
                <Dropdown
                  isMulti
                  chakraStyles={chakraStylesGradeDropdown({
                    dropdownWidth,
                    roundButtonStyle: true,
                  })}
                  isSearchable={false}
                  placeholder={t("common.grades")}
                  options={gradeLevelOptions}
                  handleChange={(grades) => {
                    handleSelectGrades(
                      (grades as IOption[]).map((grade) => grade.value)
                    );
                  }}
                  value={gradeLevelOptions.filter((gradeLevel: IOption) =>
                    query.filters.gradeLevelIds?.includes(gradeLevel.value)
                  )}
                  controlShouldRenderValue={false}
                />
              </Box>
            </HStack>
          )}
          <Button
            rightIcon={<Icon icon="slider" color="primary.dark-gray" />}
            onClick={handleOpenFilterFlyout}
            variant="adminButtonWhite"
            size={buttonSize}
            w={pxToRem(170)}
            disabled={isLoading}
          >
            {t("librarySearchResults.moreFilters")}
          </Button>
        </Stack>
      </>
    </Stack>
  );
};
