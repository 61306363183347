import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ToastId,
  useErrorToast,
  usePendingToast,
  useShowToast,
} from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { useCopyPracticeSet } from "links/lib/features/practiceSets";
import { AnalyticsEvent, IPracticeSet } from "links/lib/types";

export interface IResult {
  isCopied: boolean;
  isLoading: boolean;
  handleCopySet: (practiceSet: IPracticeSet) => void;
}

export const useCopyToMyLibrary = (): IResult => {
  const [isCopied, setIsCopied] = useState(false);
  const { showPendingToast, closePendingToast } = usePendingToast();
  const pendingToastId = useRef<ToastId | undefined>();
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "publicLibraryContainer.practiceSetDetail.copyToMyLibrary",
  });

  const onSuccess = () => {
    setIsCopied(true);

    showToast(t("successToast"));
    trackHubSpotEvent(AnalyticsEvent.HubSpot_CopiedPublicSet);
  };

  const onSettled = () => {
    closePendingToast(pendingToastId.current);
    pendingToastId.current = undefined;
  };

  const copyPracticeSet = useCopyPracticeSet({ onSuccess, onSettled });

  useErrorToast(copyPracticeSet.error);

  const handleCopySet = (practiceSet: IPracticeSet) => {
    copyPracticeSet.mutate({ id: practiceSet.id });

    closePendingToast(pendingToastId.current);
    pendingToastId.current = showPendingToast(t("pendingToast"));

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CopyPracticeSet,
      {
        practiceSetId: practiceSet.id,
        isCertified: practiceSet.is_certified,
        isPremium: practiceSet.is_premium,
      }
    );
  };

  return {
    handleCopySet,
    isCopied,
    isLoading: copyPracticeSet.isLoading,
  };
};
