import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IProps as AvatarProps } from "adminComponents/atoms/Avatar";
import { InstantSetBanner } from "adminComponents/molecules/InstantSetBanner";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { SearchTagsAndOptions } from "adminComponents/molecules/SearchTagsAndOptions";
import { LibraryParentGradePickerGrid } from "adminComponents/organisms/LibraryGradePickerGrid";
import {
  LibrarySearchResultsTabs,
  SearchResultsTab,
} from "adminComponents/organisms/LibrarySearchResultsTabs";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IHandleSetQueryArgs, ISearchQuery } from "lib/hooks/useLibraryFilter";
import { ISearchSuggestion, IStandard, ISubject } from "links/lib/types";

export interface IProps {
  isLoading: boolean;
  isSearching: boolean;
  navigationData: INavigationHeaderProps;
  selectedParentSubject?: ISubject;
  handleSelectSubjects: (subjectIds: string[]) => void;
  handleSelectGrades: (gradeLevelId: string[]) => void;
  handleCreatePracticeSet: () => void;
  handleGenerateSet: () => void;
  handleSearch?: (search: string) => void;
  query: ISearchQuery;
  suggestions?: string[];
  searchFilterSuggestions?: ISearchSuggestion[];
  tab: SearchResultsTab;
  handleTabChange: (tab: SearchResultsTab) => void;
  handleClearSearchInput?: () => void;
  handleSuggest?: (searchTerm: string) => void;
  totalQueryCount?: number;
  handleRemoveFilterTag: (
    category: "subject" | "grade" | "standard",
    id: string
  ) => void;
  handleClearAllFilters: () => void;
  handleOpenFilterFlyout: () => void;
  avatarData?: AvatarProps;
  hasNoPremiumAccess: boolean;
  standards: IStandard[];
  hideExpertTab?: boolean;
  isGuest?: boolean;
  handleUpdateQuery: (args: IHandleSetQueryArgs) => void;
}

export const LibrarySearchResults: React.FC<IProps> = ({
  isLoading,
  isSearching,
  navigationData,
  selectedParentSubject,
  handleSelectSubjects,
  handleSelectGrades,
  handleCreatePracticeSet,
  handleGenerateSet,
  handleSearch,
  query,
  suggestions,
  searchFilterSuggestions,
  tab,
  handleTabChange,
  children,
  handleClearSearchInput,
  handleSuggest,
  totalQueryCount,
  handleRemoveFilterTag,
  handleClearAllFilters,
  handleOpenFilterFlyout,
  hasNoPremiumAccess,
  standards,
  hideExpertTab = false,
  isGuest,
  handleUpdateQuery,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const showGradeLevelPicker = !query?.term && !!selectedParentSubject;

  const _handleSelectGrades = (gradeLevelId: string) => {
    handleSelectGrades([gradeLevelId]);
  };

  return (
    <Box bgColor="primary.tan-light">
      <TemplateWithCenteredHeroOneColumn
        nav={
          <NavigationHeader
            {...navigationData}
            handleCreatePracticeSet={handleCreatePracticeSet}
            handleSelectGrades={handleSelectGrades}
            handleSelectSubjects={handleSelectSubjects}
            handleSearch={handleSearch}
            handleClearSearchInput={handleClearSearchInput}
            handleSuggest={handleSuggest}
            isSearching={isSearching}
            suggestions={suggestions}
            searchFilterSuggestions={searchFilterSuggestions}
            query={query}
            selectedSubjectIds={query.filters.subjectIds}
            selectedGradeLevelIds={query.filters.gradeLevelIds}
            isGuest={false}
            hasNoPremiumAccess={hasNoPremiumAccess}
            handleUpdateQuery={handleUpdateQuery}
          />
        }
        heroContainerStyle={{
          paddingTop: pxToRem(0),
          paddingBottom: pxToRem(30),
        }}
        heroContent={
          <SearchTagsAndOptions
            handleClearAllFilters={handleClearAllFilters}
            handleOpenFilterFlyout={handleOpenFilterFlyout}
            handleRemoveFilterTag={handleRemoveFilterTag}
            handleSelectGrades={handleSelectGrades}
            handleSelectSubjects={handleSelectSubjects}
            isLoading={isLoading}
            navigationData={navigationData}
            query={query}
            totalQueryCount={totalQueryCount || 0}
            standards={standards}
            selectedParentSubject={selectedParentSubject}
          />
        }
      >
        {showGradeLevelPicker && (
          <Box
            w="full"
            borderRadius="xl"
            bgColor="primary.tan"
            overflowX="auto"
            mb={pxToRem(32)}
          >
            <LibraryParentGradePickerGrid
              px={pxToRem(20)}
              py={pxToRem(30)}
              w="full"
              h="fit-content"
              gridTemplateColumns="repeat(auto-fill,minmax(160px,1fr))"
              gridAutoFlow="column"
              gridAutoColumns="minmax(160px,1fr)"
              circleShape="type1"
              iconWidth={pxToRem(36)}
              iconHeight={pxToRem(36)}
              handleSelectGradeLevel={_handleSelectGrades}
              gradeLevels={navigationData.gradeLevels}
              fontSize={pxToRem(28)}
            />
          </Box>
        )}
        <Box paddingBottom={pxToRem(32)}>
          <LibrarySearchResultsTabs
            tabIndex={tab}
            handleTabChange={handleTabChange}
            isGuest={false}
            hideExpertTab={hideExpertTab}
          >
            {children}
          </LibrarySearchResultsTabs>
        </Box>

        <InstantSetBanner
          isLoading={isLoading}
          title={
            query?.term
              ? t("librarySearchResults.instantSetSuggestion", {
                  queryTerm: query.term,
                })
              : selectedParentSubject
              ? t("librarySearchResults.instantSetSuggestionNoQuotes", {
                  topic: selectedParentSubject?.name,
                })
              : undefined
          }
          handleGenerateInstantSet={handleGenerateSet}
          enablePremiumTooltip={hasNoPremiumAccess && !isGuest}
        />
      </TemplateWithCenteredHeroOneColumn>
    </Box>
  );
};
