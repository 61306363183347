import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet, PracticeDataDocumentType } from "../../types";

export interface ICopyPracticeSetArgs {
  id: string;
  collection_id?: string;
}

export interface ICopyPracticeSetResponse {
  practice_set: IPracticeSet;
}

export interface IUseCopyPracticeSetProps {
  onSuccess?: (data: ICopyPracticeSetResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useCopyPracticeSet(
  props?: IUseCopyPracticeSetProps
): UseMutationResult<ICopyPracticeSetResponse, unknown, ICopyPracticeSetArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const copyPracticeSet = async (args: ICopyPracticeSetArgs) => {
    const { id, collection_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/practice-sets/${id}/copy`,
      data: {
        collection_id,
      },
    }).then((r) => r.data as { practice_set: IPracticeSet });

    queryClient.invalidateQueries(["practiceSets", { id }]);
    queryClient.invalidateQueries([
      "searchPracticeData",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.invalidateQueries([
      "searchPracticeDataInfinite",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.setQueryData(
      ["practiceSets", { id: response.practice_set.id }],
      {
        practice_set: response.practice_set,
      }
    );
    queryClient.invalidateQueries("onboarding-checklist");

    return response;
  };

  const mutation = useMutation(copyPracticeSet, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
