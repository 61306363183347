import { Box, useDisclosure } from "@chakra-ui/react";
import { isEqual } from "lodash";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { usePrevious } from "react-use";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { PagedSearchResults } from "adminComponents/molecules/PagedSearchResults";
import { PracticeSetPreview } from "adminComponents/molecules/PracticeSetPreview";
import { SearchResultsTab } from "adminComponents/organisms/LibrarySearchResultsTabs";
import { LibrarySearchResults } from "adminComponents/screens/LibrarySearchResults";
import { AllSetsTab } from "adminComponents/screens/LibrarySearchResults/components/AllSetsTab";
import { TeacherHomepageImproved } from "adminComponents/screens/TeacherHomepageImproved";
import { TeacherHomepageLibrary } from "adminComponents/screens/TeacherHomepageLibrary";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { IHandleSetQueryArgs } from "lib/hooks/useLibraryFilter";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { LIBRARY_HOMEPAGE_SEARCH_PER_PAGE } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useFetchCollections } from "links/lib/features/collections";
import { useGeo } from "links/lib/features/geo";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import {
  useFetchMostCopiedCreators,
  useFetchPracticeSet,
  useFetchPracticeSetsFromFollowed,
  useFetchRecommendedPracticeSets,
  useRatePracticeSet,
} from "links/lib/features/practiceSets";
import { useFetchIndividualSessionsCount } from "links/lib/features/sessions";
import { useFetchSocialEventReactionsCount } from "links/lib/features/social";
import { useFetchStandards } from "links/lib/features/standards";
import { useGetClassroomLiveBanners } from "links/lib/hooks/useGetClassroomLiveBanners";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IPracticeSet,
  IPracticeSetSession,
  IPracticeSetType,
  PracticeSetAvailability,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useCreateSession } from "screens/TeacherMyLibrary/shared/hooks/useCreateSession";
import { useCreateAssignment } from "screens/TeacherPracticeSetDetail/hooks/useCreateAssignment";
import { useInitialQuery } from "screens/TeacherPublicLibrary/LibraryRoot/hooks/useInitialQuery";
import { useCopyToMyLibrary } from "screens/TeacherPublicLibrary/PracticeSetDetail/hooks/useCopyToMyLibrary";
import { useCreatePracticeSet } from "sharedComponents/hooks/useCreatePracticeSet";

import { useAllLibrariesSearch } from "./hooks/useAllLibrariesSearch";
import { useIsSingleParentSubjectSelected } from "./hooks/useIsSingleParentSubjectSelected";

const TeacherHome: React.FC = () => {
  const { authUser } = useAuth();
  const history = useHistory();
  const { navigationData } = useNavigationData();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "teacherHomeContainer",
  });

  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_Home);

  const fetchRecommendedPracticeSets = useFetchRecommendedPracticeSets({
    periodDays: 30,
    limit: 11,
  });
  useErrorToast(fetchRecommendedPracticeSets.error);

  const fetchMostCopiedCreators = useFetchMostCopiedCreators({
    periodDays: 30,
  });
  useErrorToast(fetchMostCopiedCreators.error);

  const fetchPracticeSetsFromFollowed = useFetchPracticeSetsFromFollowed({});
  useErrorToast(fetchPracticeSetsFromFollowed.error);

  const fetchSocialEventReactionsCount = useFetchSocialEventReactionsCount({});
  useErrorToast(fetchSocialEventReactionsCount.error);

  const fetchIndividualSessionsCount = useFetchIndividualSessionsCount({});
  useErrorToast(fetchIndividualSessionsCount.error);

  const isLoading =
    fetchRecommendedPracticeSets.isLoading ||
    fetchMostCopiedCreators.isLoading ||
    fetchPracticeSetsFromFollowed.isLoading ||
    fetchSocialEventReactionsCount.isLoading ||
    fetchIndividualSessionsCount.isLoading;

  const liveBanners = useGetClassroomLiveBanners({ showClassroomName: true });

  const handleJoinSession = (session: IPracticeSetSession) => {
    history.push(`/session/join/${session.join_code}`);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_Homepage_LiveBanner_JoinSession,
      {
        sessionId: session.id,
      }
    );
  };

  if (!authUser) {
    return <LoadingSpinner />;
  }

  return (
    <TeacherHomepageImproved
      navigationData={navigationData}
      authUser={authUser}
      isLoading={isLoading}
      socialEventReactionsCount={
        fetchSocialEventReactionsCount.data?.social_event_reactions_count || "0"
      }
      prevWindowSocialEventReactionsCount={
        fetchSocialEventReactionsCount.data
          ?.prev_window_social_event_reactions_count || "0"
      }
      individualSessionsCount={
        (fetchIndividualSessionsCount.data?.individual_sessions_count || 0) +
        (fetchIndividualSessionsCount.data?.remix_sessions_count || 0)
      }
      prevWindowIndividualSessionsCount={
        (fetchIndividualSessionsCount.data
          ?.prev_window_individual_sessions_count || 0) +
        (fetchIndividualSessionsCount.data?.prev_window_remix_sessions_count ||
          0)
      }
      recommendedPracticeSets={
        fetchRecommendedPracticeSets.data?.practice_sets || []
      }
      mostCopiedCreators={fetchMostCopiedCreators.data?.most_copied_users || []}
      newSetsFromFollowed={
        fetchPracticeSetsFromFollowed.data?.practice_sets || []
      }
      liveBanners={liveBanners}
      handleJoinSession={handleJoinSession}
    />
  );
};

export default TeacherHome;

// This component orchestrates the Q2 2024 teacher homepage redesign (making the homepage
// more of a library exploration). It is governed by the playtime.teacher.enable_library_homepage
// feature flag and should replace the above component once fully rolled out.
export const TeacherHomeLibrary: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();
  const { authUser, hasNoPremiumAccess, isFeatureEnabled } = useAuth();
  const history = useHistory();
  const { geo } = useGeo();
  const { trackHubSpotEvent } = useHubSpot();
  const { navigationData } = useNavigationData();
  const { trackEvent } = useAnalytics();
  const { t: tSharedLibraries } = useTranslation("admin", {
    keyPrefix: "sharedLibraries",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "teacherHomeContainer",
  });

  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_Home);

  const fetchRecommendedPracticeSets = useFetchRecommendedPracticeSets({
    periodDays: 30,
    limit: 18,
  });
  useErrorToast(fetchRecommendedPracticeSets.error);

  const fetchCollections = useFetchCollections();
  useErrorToast(fetchCollections.error);

  const fetchMostCopiedCreators = useFetchMostCopiedCreators({
    periodDays: 30,
  });
  useErrorToast(fetchMostCopiedCreators.error);

  const initialQuery = useInitialQuery({
    subjects: navigationData.subjects,
    authUser: authUser,
    useTeacherSubjectsAndGrades: false,
  });

  const [expertPage, setExpertPage] = useState(initialQuery.expertPage || 1);
  const [communityPage, setCommunityPage] = useState(
    initialQuery.communityPage || 1
  );
  const [domainPage, setDomainPage] = useState(initialQuery.domainPage || 1);
  const [privatePage, setPrivatePage] = useState(initialQuery.privatePage || 1);

  const showPremium = !isFeatureEnabled("playtime.teacher.hide_premium");

  const region = authUser?.region || geo?.region_short || "";

  const {
    handleClearFilters,
    handleClearSearchTerm,
    handleOpenFilterFlyout,
    handleRemoveFilterTag,
    handleSetTerm,
    handleSuggest,
    handleChangeSubjects,
    handleChangeGradeLevels,
    handleUpdateQuery,
    filterFlyout,
    standardsModal,
    totalCounts,
    isInitialLoad,
    practiceSets,
    isSearchLoading,
    query,
    subjectCounts,
    suggestions,
    searchFilterSuggestions,
  } = useAllLibrariesSearch({
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    region,
    country: authUser?.country || "US",
    expertPage,
    communityPage,
    domainPage,
    privatePage,
    perPage: LIBRARY_HOMEPAGE_SEARCH_PER_PAGE,
    initialQuery,
    showPremiumFilter: showPremium,
    authUserId: authUser?.id,
  });

  const fetchStandards = useFetchStandards({
    ids: query.filters.standardIds,
    offset: 0,
    limit: (query.filters.standardIds || []).length,
    enabled: (query.filters.standardIds || []).length > 0,
  });
  useErrorToast(fetchStandards.error);

  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (typeof prevQuery !== "undefined" && !isEqual(query, prevQuery)) {
      setExpertPage(1);
      setCommunityPage(1);
      setDomainPage(1);
      setPrivatePage(1);

      trackEvent(AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_Search, {
        hasTerm: !!query.term,
        hasStandards: !!query.filters.standardIds.length,
        hasSubjects: !!query.filters.subjectIds.length,
        hasGrades: !!query.filters.gradeLevelIds.length,
        hasCertifiedOnly: query.filters.certifiedOnly,
        hasHidePremium: query.filters.hidePremium,
      });
    }
  }, [query, prevQuery, trackEvent]);

  const handleSearch = useCallback(
    (search: string) => {
      const params = qs.stringify(
        {
          term: search,
          expertPage: 1,
          communityPage: 1,
          domainPage: 1,
          privatePage: 1,
          ...query.filters,
        },
        { encodeValuesOnly: true }
      );

      history.push(`/t/explore?${params}`);
    },
    [history, query]
  );

  const _handleChangeGradeLevels = (gradeLevelIds: string[]) => {
    const gradeLevelIdsSet = new Set(query.filters.gradeLevelIds);
    gradeLevelIds.forEach((gradeLevelId) => gradeLevelIdsSet.add(gradeLevelId));
    handleChangeGradeLevels(Array.from(gradeLevelIdsSet));
  };

  const _handleUpdateQuery = ({
    term,
    gradeLevelIds,
    subjectIds,
  }: IHandleSetQueryArgs) => {
    const gradeLevelIdsSet = new Set(query.filters.gradeLevelIds);
    gradeLevelIds.forEach((gradeLevelId) => gradeLevelIdsSet.add(gradeLevelId));

    const subjectIdsSet = new Set(query.filters.subjectIds);
    subjectIds.forEach((subjectId) => subjectIdsSet.add(subjectId));

    handleUpdateQuery({
      term,
      gradeLevelIds: Array.from(gradeLevelIdsSet),
      subjectIds: Array.from(subjectIdsSet),
    });
  };

  const _handleClearAllFilters = () => {
    handleClearFilters();
  };

  const shouldRenderSearchResults =
    !!query.filters.standardIds.length ||
    !!query.filters.subjectIds.length ||
    !!query.filters.gradeLevelIds.length ||
    !!query.term;

  const params = useMemo(() => {
    return qs.stringify(
      {
        expertPage,
        communityPage,
        domainPage,
        privatePage,
        term: query.term,
        ...query.filters,
      },
      { encodeValuesOnly: true }
    );
  }, [
    domainPage,
    privatePage,
    expertPage,
    communityPage,
    query.filters,
    query.term,
  ]);

  useEffect(() => {
    if (shouldRenderSearchResults) {
      const path = !tab ? "/t/explore/all" : location.pathname;
      history.push(`${path}?${params}`);
    } else {
      history.push("/t/explore");
    }
  }, [
    communityPage,
    domainPage,
    expertPage,
    privatePage,
    history,
    params,
    query.filters,
    query.term,
    shouldRenderSearchResults,
    tab,
  ]);

  const {
    isOpen: isPreviewSetFlyoutOpen,
    onOpen: onOpenPreviewSetFlyout,
    onClose: onClosePreviewSetFlyout,
  } = useDisclosure();
  const [searchResultsTab, setSearchResultsTab] = useState(
    tab === "all"
      ? SearchResultsTab.All
      : tab === "private"
      ? SearchResultsTab.MySets
      : tab === "domain"
      ? SearchResultsTab.MyDistrict
      : tab === "experts"
      ? SearchResultsTab.FromExperts
      : tab === "community"
      ? SearchResultsTab.FromCommunity
      : SearchResultsTab.All
  );
  const [previewSet, setPreviewSet] = useState<IPracticeSet>();
  const fetchPreviewSet = useFetchPracticeSet({
    id: previewSet?.id || "0",
  });
  useErrorToast(fetchPreviewSet.error);
  const fetchPreviewSetItems = useFetchPracticeSetItems({
    practice_set_id: previewSet?.id || "0",
  });
  useErrorToast(fetchPreviewSetItems.error);

  const handleCreateSessionConfirm = useCallback(() => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_StartSession,
      {
        practiceSetId: previewSet?.id,
        isCertified: previewSet?.is_certified || false,
      }
    );
    trackHubSpotEvent(
      previewSet?.availability === PracticeSetAvailability.Private
        ? AnalyticsEvent.HubSpot_MyLibraryStartLiveSession
        : AnalyticsEvent.HubSpot_SharedLibraryStartLiveSession
    );
  }, [previewSet, trackEvent, trackHubSpotEvent]);
  const {
    createSessionElement,
    handleCreateIntent: handleCreateSessionIntent,
  } = useCreateSession({
    useModal: true,
    practiceSetCreatedById: previewSet?.created_by || "0",
    practiceSetId: previewSet?.id || "0",
    onSubmit: handleCreateSessionConfirm,
  });

  const ratePracticeSet = useRatePracticeSet();
  const handleRate = (rating: number) => {
    if (!previewSet) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_RatePracticeSet,
      {
        practiceSetId: previewSet.id,
        isCertified: previewSet.is_certified || false,
        rating,
      }
    );

    ratePracticeSet.mutate({ rating, practiceSetId: previewSet.id });
  };

  const {
    handleCopySet,
    isCopied: isSetCopied,
    isLoading: isCopyingSet,
  } = useCopyToMyLibrary();

  const practiceSetUrl = useMemo(() => {
    if (!previewSet) return "";
    return getPracticeSetUrl(previewSet, authUser);
  }, [authUser, previewSet]);

  const {
    component: createAssignmentFlyout,
    handleCreateIntent: handleCreateAssignment,
  } = useCreateAssignment({
    practiceSet: previewSet,
    intentEvent:
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CreateAssignmentIntent,
    confirmEvent:
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CreateAssignmentConfirm,
  });

  const previewSetFlyout = (
    <>
      <AdminFlyout
        title=""
        isOpen={isPreviewSetFlyoutOpen}
        onClose={onClosePreviewSetFlyout}
      >
        <PracticeSetPreview
          isGuest={false}
          hasStartedLivePractice={
            navigationData.teacherOnboardingChecklist?.has_started_live_practice
          }
          practiceSet={fetchPreviewSet.data?.practice_set}
          personalRating={fetchPreviewSet.data?.personal_rating || 0}
          items={fetchPreviewSetItems.data?.practice_set_items || []}
          handlePractice={() => handleCreateSessionIntent()}
          handleRate={handleRate}
          handleCopy={handleCopySet}
          handleAssign={handleCreateAssignment}
          handleDetails={() => history.push(practiceSetUrl)}
          handlePreview={() => {
            history.push(`${practiceSetUrl}/preview`);
          }}
          isCopyLoading={isCopyingSet}
          isSetCopied={isSetCopied}
        />
      </AdminFlyout>
    </>
  );

  const handlePracticeSetPreview = (practiceSet: IPracticeSet) => {
    setPreviewSet(practiceSet);
    onOpenPreviewSetFlyout();
  };

  const {
    flyout: createPracticeSetFlyout,
    handleCreate: handleCreatePracticeSet,
  } = useCreatePracticeSet({
    subjects: navigationData.subjects,
    gradeLevels: navigationData.gradeLevels,
    collections: fetchCollections.data?.collections || [],
  });

  const {
    flyout: createInstantSetFlyout,
    handleCreate: handleCreateInstantSet,
  } = useCreatePracticeSet({
    subjects: navigationData.subjects,
    gradeLevels: navigationData.gradeLevels,
    collections: fetchCollections.data?.collections || [],
    practiceSetType: IPracticeSetType.Instant,
  });

  const handleTabChange = useCallback(
    (tab: SearchResultsTab) => {
      setSearchResultsTab(tab);
      switch (tab) {
        case SearchResultsTab.All:
          history.push(`/t/explore/all?${params}`);
          break;
        case SearchResultsTab.MySets:
          history.push(`/t/explore/private?${params}`);
          break;
        case SearchResultsTab.MyDistrict:
          history.push(`/t/explore/domain?${params}`);
          break;
        case SearchResultsTab.FromExperts:
          history.push(`/t/explore/experts?${params}`);
          break;
        case SearchResultsTab.FromCommunity:
          history.push(`/t/explore/community?${params}`);
          break;
      }
    },
    [history, params]
  );

  // The UI changes slightly if one parent subject is selected.
  // If either the parent subject is selected or all the selected
  // child subjects belong to the same parent, singleSelectedParentSubject
  // will be defined and equal to the parent subject.
  const singleSelectedParentSubject = useIsSingleParentSubjectSelected(
    query,
    navigationData.subjects,
    navigationData.parentSubjects
  );

  const hideExpertTab =
    !isSearchLoading && totalCounts.practiceSets.expert === 0;

  if (shouldRenderSearchResults) {
    return (
      <>
        <LibrarySearchResults
          isLoading={isInitialLoad}
          isSearching={isSearchLoading}
          navigationData={navigationData}
          selectedParentSubject={singleSelectedParentSubject}
          handleSelectSubjects={handleChangeSubjects}
          handleSelectGrades={_handleChangeGradeLevels}
          handleCreatePracticeSet={handleCreatePracticeSet}
          handleGenerateSet={handleCreateInstantSet}
          query={query}
          totalQueryCount={totalCounts.practiceSets.total}
          tab={searchResultsTab}
          handleTabChange={handleTabChange}
          handleRemoveFilterTag={handleRemoveFilterTag}
          handleClearAllFilters={_handleClearAllFilters}
          handleOpenFilterFlyout={handleOpenFilterFlyout}
          handleSearch={handleSearch}
          handleClearSearchInput={handleClearSearchTerm}
          handleSuggest={handleSuggest}
          standards={fetchStandards.data?.standards || []}
          hasNoPremiumAccess={hasNoPremiumAccess}
          hideExpertTab={hideExpertTab}
          isGuest={authUser?.is_guest}
          suggestions={suggestions}
          searchFilterSuggestions={searchFilterSuggestions}
          handleUpdateQuery={_handleUpdateQuery}
        >
          <Box my={pxToRem(32)}>
            <Switch>
              <Route path="/t/explore/all">
                <AllSetsTab
                  isLoading={isSearchLoading}
                  handleTabChange={handleTabChange}
                  setsFromExperts={practiceSets.expert}
                  setsFromExpertsCount={totalCounts.practiceSets.expert}
                  setsFromCommunity={practiceSets.community}
                  setsFromCommunityCount={totalCounts.practiceSets.community}
                  onPracticeSetPreview={handlePracticeSetPreview}
                  mySets={practiceSets.private}
                  mySetsCount={totalCounts.practiceSets.private}
                  myDistrictSets={practiceSets.domain}
                  myDistrictSetsCount={totalCounts.practiceSets.domain}
                  hideExpertTab={hideExpertTab}
                />
              </Route>
              <Route path="/t/explore/experts">
                <PagedSearchResults
                  practiceSets={practiceSets.expert}
                  isLoading={isSearchLoading}
                  page={expertPage}
                  perPage={LIBRARY_HOMEPAGE_SEARCH_PER_PAGE}
                  totalCount={totalCounts.practiceSets.expert}
                  handleSetPage={setExpertPage}
                  onPracticeSetPreview={handlePracticeSetPreview}
                  emptyStateTitle={tSharedLibraries("emptyTitlePublic")}
                />
              </Route>

              <Route path="/t/explore/community">
                <PagedSearchResults
                  practiceSets={practiceSets.community}
                  isLoading={isSearchLoading}
                  page={communityPage}
                  perPage={LIBRARY_HOMEPAGE_SEARCH_PER_PAGE}
                  totalCount={totalCounts.practiceSets.community}
                  handleSetPage={setCommunityPage}
                  onPracticeSetPreview={handlePracticeSetPreview}
                  emptyStateTitle={tSharedLibraries("emptyTitlePublic")}
                />
              </Route>
              <Route path="/t/explore/private">
                <PagedSearchResults
                  practiceSets={practiceSets.private}
                  isLoading={isSearchLoading}
                  page={privatePage}
                  perPage={LIBRARY_HOMEPAGE_SEARCH_PER_PAGE}
                  totalCount={totalCounts.practiceSets.private}
                  handleSetPage={setPrivatePage}
                  onPracticeSetPreview={handlePracticeSetPreview}
                  emptyStateTitle={tSharedLibraries("emptyTitlePrivate")}
                />
              </Route>
              <Route path="/t/explore/domain">
                <PagedSearchResults
                  practiceSets={practiceSets.domain}
                  isLoading={isSearchLoading}
                  page={domainPage}
                  perPage={LIBRARY_HOMEPAGE_SEARCH_PER_PAGE}
                  totalCount={totalCounts.practiceSets.domain}
                  handleSetPage={setDomainPage}
                  onPracticeSetPreview={handlePracticeSetPreview}
                  emptyStateTitle={tSharedLibraries("emptyTitleDomain")}
                />
              </Route>
            </Switch>
          </Box>
        </LibrarySearchResults>
        {filterFlyout}
        {standardsModal}
        {previewSetFlyout}
        {createInstantSetFlyout}
        {createPracticeSetFlyout}
        {createAssignmentFlyout}
        <Box key={previewSet?.id}>{createSessionElement}</Box>
      </>
    );
  }

  return (
    <>
      <TeacherHomepageLibrary
        isGuest={false}
        navigationData={navigationData}
        subjectCounts={subjectCounts}
        recommendedPracticeSets={
          fetchRecommendedPracticeSets.data?.practice_sets || []
        }
        mostCopiedCreators={
          fetchMostCopiedCreators.data?.most_copied_users || []
        }
        query={query}
        suggestions={suggestions}
        isLoading={isInitialLoad}
        isSearching={isSearchLoading}
        handleSearch={handleSearch}
        handleSuggest={handleSuggest}
        handleSetSearchTerm={handleSetTerm}
        handleClearSearchInput={handleClearSearchTerm}
        handleSelectSubjects={handleChangeSubjects}
        handleSelectGrades={_handleChangeGradeLevels}
        handleCreatePracticeSet={handleCreatePracticeSet}
        handleGenerateSet={handleCreateInstantSet}
        handlePracticeSetPreview={handlePracticeSetPreview}
        handleOpenFilterFlyout={handleOpenFilterFlyout}
        searchFilterSuggestions={searchFilterSuggestions}
        handleUpdateQuery={_handleUpdateQuery}
      />
      {filterFlyout}
      {standardsModal}
      {previewSetFlyout}
      {createInstantSetFlyout}
      {createPracticeSetFlyout}
      {createAssignmentFlyout}
      <Box key={previewSet?.id}>{createSessionElement}</Box>
    </>
  );
};
