import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { PracticeSetSection } from "adminComponents/molecules/PracticeSetSection";
import {
  GuestSearchResultsTab,
  SearchResultsTab,
} from "adminComponents/organisms/LibrarySearchResultsTabs";
import { IPracticeSet } from "links/lib/types";

export interface IProps {
  isLoading: boolean;
  handleTabChange: (tab: GuestSearchResultsTab | SearchResultsTab) => void;
  setsFromExperts: IPracticeSet[];
  setsFromExpertsCount: number;
  setsFromCommunity: IPracticeSet[];
  setsFromCommunityCount: number;
  onPracticeSetPreview: (set: IPracticeSet) => void;
  hideExpertTab?: boolean;
}

export const AllSetsTab: React.FC<IProps> = ({
  isLoading,
  handleTabChange,
  setsFromExperts,
  setsFromExpertsCount,
  setsFromCommunity,
  setsFromCommunityCount,
  onPracticeSetPreview,
  hideExpertTab = false,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box bgColor="primary.tan-light">
      <VStack>
        {!hideExpertTab && (
          <PracticeSetSection
            count={setsFromExpertsCount}
            isLoading={isLoading}
            practiceSets={setsFromExperts}
            handleViewMore={() => {
              handleTabChange(GuestSearchResultsTab.FromExperts);
            }}
            title={t("librarySearchResults.fromExperts")}
            onPracticeSetPreview={onPracticeSetPreview}
          />
        )}
        <PracticeSetSection
          count={setsFromCommunityCount}
          isLoading={isLoading}
          practiceSets={setsFromCommunity}
          handleViewMore={() => {
            handleTabChange(GuestSearchResultsTab.FromCommunity);
          }}
          title={t("librarySearchResults.fromCommunity")}
          onPracticeSetPreview={onPracticeSetPreview}
        />
      </VStack>
    </Box>
  );
};
