import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { TeacherPracticeSetScreen } from "adminComponents/screens/TeacherPracticeSetScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import {
  useFetchPracticeSet,
  useRatePracticeSet,
} from "links/lib/features/practiceSets";
import {
  AnalyticsEvent,
  AnalyticsPage,
  PracticeSetAvailability,
} from "links/lib/types";
import AppError from "screens/AppError";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useCreateSession } from "screens/TeacherMyLibrary/shared/hooks/useCreateSession";
import { useCreateAssignment } from "screens/TeacherPracticeSetDetail/hooks/useCreateAssignment";

import { useCopyItemToSet } from "./hooks/useCopyItemToSet";
import { useCopyToMyLibrary } from "./hooks/useCopyToMyLibrary";

export interface IPracticeSetDetailProps {
  availability: PracticeSetAvailability;
}

export const PracticeSetDetail: React.FC<IPracticeSetDetailProps> = ({
  availability,
}: IPracticeSetDetailProps) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();
  const { navigationData } = useNavigationData();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "publicLibraryContainer.practiceSetDetail",
  });
  const { authUser, isFeatureEnabled } = useAuth();

  usePageTrack(AnalyticsPage.TeacherDashboard_PublicLibrary_PracticeSetDetail);

  const [practiceSetIsForbidden, setPracticeSetIsForbidden] = useState(false);
  const fetchPracticeSet = useFetchPracticeSet({
    id,
    onNotFound: () => {
      history.push("/t/public-library");
    },
    onForbidden: () => setPracticeSetIsForbidden(true),
  });
  const fetchPracticeSetItems = useFetchPracticeSetItems({
    practice_set_id: id,
  });

  useErrorToast(fetchPracticeSet.error);
  useErrorToast(fetchPracticeSetItems.error);

  const practiceSet = fetchPracticeSet.data?.practice_set;
  const personalRating = fetchPracticeSet.data?.personal_rating;

  // Redirect according to user's relation to the practice set
  useEffect(() => {
    if (!practiceSet) return;
    if (!authUser) return;

    const practiceSetUrl = getPracticeSetUrl(practiceSet, authUser);
    if (!location.pathname.startsWith(practiceSetUrl)) {
      history.push(practiceSetUrl);
    }
  }, [practiceSet, authUser, history]);

  const practiceSetUrl = useMemo(() => {
    if (!practiceSet) return "";
    return getPracticeSetUrl(practiceSet, authUser);
  }, [authUser, practiceSet]);

  usePageTitle([practiceSet?.title, t("publicLibraryBreadcrumb")]);

  const {
    handleCopySet,
    isCopied: isSetCopied,
    isLoading: isCopyingSet,
  } = useCopyToMyLibrary();

  const { handleAddToSet, modal: addToSetModal } = useCopyItemToSet();

  const ratePracticeSet = useRatePracticeSet();
  const handleRate = (rating: number) => {
    if (!practiceSet) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_RatePracticeSet,
      {
        practiceSetId: id,
        isCertified: practiceSet?.is_certified || false,
        rating,
      }
    );

    ratePracticeSet.mutate({ rating, practiceSetId: practiceSet.id });
  };

  const breadcrumbs =
    availability === PracticeSetAvailability.Public
      ? [
          {
            label: t("publicLibraryBreadcrumb"),
            to: "/t/public-library",
          },
          {
            label: practiceSet?.title ?? "",
            to: practiceSetUrl,
          },
        ]
      : [
          {
            label: t("domainLibraryBreadcrumb"),
            to: "/t/my-school-library",
          },
          {
            label: practiceSet?.title ?? "",
            to: practiceSetUrl,
          },
        ];

  const noOp = () => {
    return;
  };

  const practiceSetDetailData = {
    practiceSet,
    isOpen: false,
    showCncFields: false,
    isLoading: false,
    options: {
      folders: [],
      subjects: [],
      gradeLevels: [],
      languages: [],
    },
    handleSubmit: noOp,
    handleCoverImageChange: noOp,
    handleClose: noOp,
  };

  const practiceSetQuestionOrderData = {
    handleSaveOrder: noOp,
    handleStartOrder: noOp,
    handleCancelOrder: noOp,
    isLoading: false,
  };

  const practiceSetAddQuestionsData = {
    searchLoading: false,
    isOpen: false,
    isGenerateItemFormOpen: false,
    setIsGenerateItemFormOpen: noOp,
    subjects: [],
    grades: [],
    libraries: [],
    practiceSetItems: [],
    states: [],
    handleChangeFilter: noOp,
    handleAddToSet: noOp,
    handleSearch: noOp,
    handleAddNew: noOp,
    handleClose: noOp,
    showPremiumMarker: !isFeatureEnabled("playtime.teacher.hide_premium"),
    showGenerateItemOption: false,
    generateItemIsLoading: false,
    handleGenerateItem: noOp,
    handleEditPracticeSet: noOp,
  };

  const handlePreviewPracticeSet = () => {
    history.push(`${practiceSetUrl}/preview`);
  };

  const handleCreateSessionConfirm = () => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_StartSession,
      { practiceSetId: id, isCertified: practiceSet?.is_certified || false }
    );
    trackHubSpotEvent(AnalyticsEvent.HubSpot_SharedLibraryStartLiveSession);
  };

  const {
    createSessionElement,
    handleCreateIntent: handleCreateSessionIntent,
  } = useCreateSession({
    practiceSetId: id,
    practiceSetCreatedById: practiceSet?.created_by || "",
    onSubmit: handleCreateSessionConfirm,
  });

  const handleLiveSession = () => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_StartSessionIntent,
      { practiceSetId: id }
    );
    handleCreateSessionIntent();
  };

  const {
    component: createAssignmentFlyout,
    handleCreateIntent: handleCreateAssignment,
  } = useCreateAssignment({
    practiceSet,
    intentEvent:
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CreateAssignmentIntent,
    confirmEvent:
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CreateAssignmentConfirm,
  });

  if (practiceSetIsForbidden) {
    return <AppError code={403} />;
  }

  return (
    <>
      <TeacherPracticeSetScreen
        authorName={practiceSet?.author_name || ""}
        personalRating={personalRating}
        authorProfileImageUrl={practiceSet?.author_profile_image_url || ""}
        authorProfileIsPrivate={practiceSet?.author_profile_is_private || false}
        authorIsInternalContentSpecialist={
          practiceSet?.author_is_internal_content_specialist
        }
        authorCustomUrlCode={practiceSet?.author_custom_url_code || ""}
        isPublic
        isLoading={
          fetchPracticeSet.isLoading || fetchPracticeSetItems.isLoading
        }
        practiceSetQuestionsData={
          fetchPracticeSetItems.data?.practice_set_items || []
        }
        navigationData={{ ...navigationData, breadcrumbs }}
        practiceSetDetailData={practiceSetDetailData}
        practiceSetQuestionOrderData={practiceSetQuestionOrderData}
        practiceSetAddQuestionsData={practiceSetAddQuestionsData}
        practiceSet={practiceSet}
        handlePreviewPracticeSet={handlePreviewPracticeSet}
        handleDeletePracticeSet={noOp}
        handleDuplicatePracticeSet={noOp}
        handleDeleteQuestion={noOp}
        handleAddQuestion={noOp}
        handleDuplicateQuestion={noOp}
        handleEditPracticeSet={noOp}
        handleCreateAssignment={handleCreateAssignment}
        handleLiveSession={handleLiveSession}
        handleEditQuestion={noOp}
        handlePreviewQuestion={noOp}
        handleCopyToMyLibrary={handleCopySet}
        handleAddQuestionToSet={handleAddToSet}
        handleRate={handleRate}
        isCopiedToLibrary={isSetCopied}
        isCopyingToLibrary={isCopyingSet}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
      />
      {addToSetModal}
      {createSessionElement}
      {createAssignmentFlyout}
    </>
  );
};
