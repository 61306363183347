import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMount } from "react-use";

import { CreateClassificationTabForm } from "adminComponents/molecules/CreateQuestionClassificationTab";
import { CreateDiagramTabForm } from "adminComponents/molecules/CreateQuestionDiagramTab";
import { CreateDrawTabForm } from "adminComponents/molecules/CreateQuestionDrawTab";
import { CreateFeedbackTabForm } from "adminComponents/molecules/CreateQuestionFeedbackTab";
import { CreateInternalTabForm } from "adminComponents/molecules/CreateQuestionInternalTab";
import { CreateMultipleChoiceTabForm } from "adminComponents/molecules/CreateQuestionMultipleChoiceTab";
import { CreateNumberResponseTabForm } from "adminComponents/molecules/CreateQuestionNumberResponseTab";
import { CreateStandardTabForm } from "adminComponents/molecules/CreateQuestionStandardsTab";
import { CreateTermTabForm } from "adminComponents/molecules/CreateQuestionTermTab";
import { CreateTextResponseTabForm } from "adminComponents/molecules/CreateQuestionTextResponseTab";
import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { QuestionFlyout } from "adminComponents/organisms/QuestionFlyout";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";
import { regionMap } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchPracticeSetItem,
  useMutateClassify,
  useMutateDiagram,
  useMutateDraw,
  useMutateMultipleChoice,
  useMutateNumberResponse,
  useMutateTermDefinition,
  useMutateTextResponse,
} from "links/lib/features/practiceSetItems";
import { IMutateDiagramArgs } from "links/lib/features/practiceSetItems/useMutatateDiagram";
import { IMutateClassifyArgs } from "links/lib/features/practiceSetItems/useMutateClassify";
import { IMutateDrawArgs } from "links/lib/features/practiceSetItems/useMutateDraw";
import { IMutateMultipleChoiceArgs } from "links/lib/features/practiceSetItems/useMutateMultipleChoice";
import { IMutateNumberResponseArgs } from "links/lib/features/practiceSetItems/useMutateNumberResponse";
import { IMutateTermDefinitionArgs } from "links/lib/features/practiceSetItems/useMutateTermDefinition";
import { IMutateTextResponseArgs } from "links/lib/features/practiceSetItems/useMutateTextResponse";
import useSearchStandardDataOnce from "links/lib/features/search/useSearchStandardDataOnce";
import { useFetchSkillsOnce } from "links/lib/features/skills";
import {
  IPracticeSet,
  IPracticeSetItem,
  QuestionType,
  UserRole,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { useGenerateFeedback } from "./hooks/useGenerateFeedback";
import useItemAnalyticsGeneric from "./hooks/useItemAnalyticsGeneric";

export interface IPracticeSetItemFlyoutProps {
  practiceSet: IPracticeSet;
  items: Array<IPracticeSetItem>;
  showPremiumMarker?: boolean;
}

const PracticeSetItemFlyout: React.FC<IPracticeSetItemFlyoutProps> = ({
  practiceSet,
  items,
  showPremiumMarker = true,
}) => {
  const { questionType, itemId } =
    useParams<{ questionType: string; itemId: string }>();
  const location: {
    state?: { imageMode: boolean; practiceSetItem?: IPracticeSetItem };
  } = useLocation();
  const { authUser } = useAuth();
  const { navigationData } = useNavigationData();
  const isInternalUser = authUser?.role === UserRole.ContentSpecialist;
  const history = useHistory();
  const showToast = useShowToast();
  const [redirectToItemId, setRedirectToItemId] = useState<string>("");
  const { t } = useTranslation("translation", {
    keyPrefix: "teacherPracticeLibrary.practiceSetItemDetail",
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
  });
  const { trackItemMutation } = useItemAnalyticsGeneric({
    practiceSet,
    itemId,
  });

  // Attempt to get the default subject for standards picker
  // by finding all the parent-most subjects tagged to the
  // practice set and if all are the same, then default to that
  // parent subject id
  const defaultSubject = useMemo((): string => {
    if (!practiceSet.subjects.length) return "";

    const parentSubjectsIdMap: { [key: string]: true } = {};

    practiceSet.subjects.forEach((subject) => {
      const parentId =
        subject.subject.parent_id === "0"
          ? subject.subject.id
          : subject.subject.parent_id;
      parentSubjectsIdMap[parentId] = true;
    });

    const parentSubjects = Object.keys(parentSubjectsIdMap);

    if (parentSubjects.length === 1) return parentSubjects[0];

    return "";
  }, [practiceSet.subjects]);

  const fetchStandards = useSearchStandardDataOnce();
  const fetchSkills = useFetchSkillsOnce();
  const executeFetchStandards = useRef(fetchStandards.execute);
  const executeFetchSkills = useRef(fetchSkills.execute);

  const [standardsSearchQuery, setStandardsSearchQuery] = useState("");
  const [skillsSearchQuery, setSkillsSearchQuery] = useState("");
  const [regionFilter, setRegionFilter] = useState(authUser?.region || "");
  const [subjectIdFilter, setSubjectIdFilter] = useState(defaultSubject);
  const [gradeLevelIdFilter, setGradeLevelIdFilter] = useState(
    practiceSet.grade_levels.length === 1
      ? practiceSet.grade_levels[0].grade_level.id
      : ""
  );
  const [standardCollectionIdFilter, setStandardCollectionIdFilter] =
    useState("");

  const [regionOptions, subjectOptions, gradeLevelOptions] = useMemo(() => {
    const regions = authUser?.country ? regionMap[authUser.country] : {};
    const regionOptions = Object.keys(regions).map((region) => ({
      value: region,
      label: regions[region],
    }));
    const anyRegion = {
      label: tCommon("searchFilterOptionAnyState"),
      value: "",
    };
    const subjectOptions = navigationData.subjects
      .filter((s) => s.parent_id === "0")
      .map((s) => ({
        label: s.name,
        value: s.id,
      }));
    const anySubject = {
      label: tCommon("searchFilterOptionAnySubject"),
      value: "",
    };
    const gradeLevelOptions = navigationData.gradeLevels.map((gl) => ({
      value: gl.id,
      label: gl.grade_level,
    }));
    const anyGradeLevel = {
      label: tCommon("searchFilterOptionAnyGrade"),
      value: "",
    };

    return [
      [anyRegion].concat(regionOptions),
      [anySubject].concat(subjectOptions),
      [anyGradeLevel].concat(gradeLevelOptions),
    ];
  }, [
    authUser?.country,
    navigationData.gradeLevels,
    navigationData.subjects,
    tCommon,
  ]);

  let practiceSetItem = items.find((item) => item.id === itemId);

  // Set if some basics of this item were generated by AI
  const generatedItem = location.state?.practiceSetItem;
  if (generatedItem) {
    practiceSetItem = generatedItem;
  }

  let typedQuestionType: QuestionType;
  let translationsKey = "";
  switch (questionType) {
    case "term":
      typedQuestionType = QuestionType.TermDefinition;
      translationsKey = "termDefinition";
      break;
    case "text-response":
      typedQuestionType = QuestionType.TextResponse;
      translationsKey = "textResponse";
      break;
    case "number-response":
      typedQuestionType = QuestionType.NumberResponse;
      translationsKey = "numberResponse";
      break;
    case "multiple-choice":
      typedQuestionType = practiceSetItem?.multiple_choice
        ? QuestionType.MultipleChoice
        : QuestionType.MultipleSelect;
      translationsKey = "multipleChoice";
      break;
    case "draw":
      typedQuestionType = QuestionType.Draw;
      translationsKey = "draw";
      break;
    case "diagram":
      typedQuestionType = QuestionType.Diagram;
      translationsKey = "diagram";
      break;
    case "classify":
      typedQuestionType = QuestionType.Classify;
      translationsKey = "classify";
      break;
    default:
      typedQuestionType = QuestionType.Unknown;
  }

  const isItemDetailOpen = !!questionType;

  // True if this is a brand new question that was chosen to be image-based
  let isImageMode = location.state?.imageMode;
  if (practiceSetItem?.question_type === QuestionType.MultipleChoice) {
    isImageMode = (practiceSetItem.multiple_choice?.choices || []).some(
      (choice) => !!choice.image_url
    );
  }
  if (practiceSetItem?.question_type === QuestionType.MultipleSelect) {
    isImageMode = (practiceSetItem.multiple_select?.choices || []).some(
      (choice) => !!choice.image_url
    );
  }
  if (practiceSetItem?.question_type === QuestionType.Classify) {
    isImageMode = (practiceSetItem.classify?.categories || []).some(
      (category) => category.choices.some((choice) => !!choice.image_url)
    );
  }

  // Fetch data to feed into create/edit flyout
  useMount(() => {
    executeFetchStandards.current({
      country: authUser?.country || "",
      region: regionFilter,
      grade_level_ids: gradeLevelIdFilter ? [gradeLevelIdFilter] : undefined,
      subject_ids: subjectIdFilter ? [subjectIdFilter] : undefined,
      standard_collection_id: standardCollectionIdFilter || undefined,
      term: standardsSearchQuery,
      page: 1,
      per_page: 10,
    });

    if (isInternalUser) {
      executeFetchSkills.current({
        limit: 10,
        offset: 0,
        query: skillsSearchQuery,
      });
    }
  });

  const itemFetch = useFetchPracticeSetItem({
    id: itemId,
    isQueryEnabled: !!itemId,
  });

  const {
    isLoading: isGeneratingFeedback,
    data: generatedFeedbackData,
    mutate: handleGenerateFeedback,
  } = useGenerateFeedback({
    onSuccess: (result) => setGeneratedFeedback(result.feedback),
  });
  const [generatedFeedback, setGeneratedFeedback] = useState(
    generatedFeedbackData?.feedback
  );

  // on successful mutate, redirect back to items list
  const onSuccess = (questionType: QuestionType) => {
    return () => {
      setGeneratedFeedback(undefined);
      trackItemMutation(questionType);
      showToast(t(`${translationsKey}.submitSuccess`));
      history.push(getPracticeSetUrl(practiceSet, authUser));
    };
  };

  const termDefinitionMutation = useMutateTermDefinition({
    onSuccess: onSuccess(QuestionType.TermDefinition),
  });
  const textResponseMutation = useMutateTextResponse({
    onSuccess: onSuccess(QuestionType.TextResponse),
  });
  const numberResponseMutation = useMutateNumberResponse({
    onSuccess: onSuccess(QuestionType.NumberResponse),
  });
  const multipleChoiceMutation = useMutateMultipleChoice({
    onSuccess: onSuccess(QuestionType.MultipleChoice),
  });
  const multipleSelectMutation = useMutateMultipleChoice({
    onSuccess: onSuccess(QuestionType.MultipleSelect),
  });
  const drawMutation = useMutateDraw({
    onSuccess: onSuccess(QuestionType.Draw),
  });
  const diagramMutation = useMutateDiagram({
    onSuccess: onSuccess(QuestionType.Diagram),
  });
  const classifyMutation = useMutateClassify({
    onSuccess: onSuccess(QuestionType.Classify),
  });

  const onItemDetailClose = (redirectToItemId?: string) => {
    setGeneratedFeedback(undefined);
    history.push(getPracticeSetUrl(practiceSet, authUser));

    if (redirectToItemId) {
      setRedirectToItemId(redirectToItemId);
    }
  };

  useEffect(() => {
    if (!redirectToItemId) return;

    const item = items.find((item) => item.id === redirectToItemId);

    setRedirectToItemId("");

    if (!item) return;

    setTimeout(() => {
      switch (item.question_type) {
        case QuestionType.Diagram:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/diagram/${item.id}`
          );
          break;
        case QuestionType.MultipleChoice:
        case QuestionType.MultipleSelect:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/multiple-choice/${item.id}`
          );
          break;
        case QuestionType.TermDefinition:
          history.push(`/t/my-library/sets/${practiceSet.id}/term/${item.id}`);
          break;
        case QuestionType.OpenEnded:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/open-ended/${item.id}`
          );
          break;
        case QuestionType.TextResponse:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/text-response/${item.id}`
          );
          break;
        case QuestionType.NumberResponse:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/number-response/${item.id}`
          );
          break;
        case QuestionType.Classify:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/classify/${item.id}`
          );
          break;
        case QuestionType.Draw:
          history.push(`/t/my-library/sets/${practiceSet.id}/draw/${item.id}`);
          break;
      }
    }, 300);
  }, [items, history, practiceSet.id, redirectToItemId]);

  const handleStandardsFilterChange = (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => {
    setRegionFilter(state);
    setSubjectIdFilter(subject);
    setGradeLevelIdFilter(grade);
    setStandardCollectionIdFilter(standardCollection || "");

    executeFetchStandards.current({
      country: authUser?.country || "",
      region: state,
      grade_level_ids: grade ? [grade] : undefined,
      subject_ids: subject ? [subject] : undefined,
      standard_collection_id: standardCollection || undefined,
      term: standardsSearchQuery,
      page: 1,
      per_page: 10,
    });
  };

  const handleStandardsSearch = (search: string) => {
    setStandardsSearchQuery(search);

    executeFetchStandards.current({
      country: authUser?.country || "",
      region: regionFilter,
      grade_level_ids: gradeLevelIdFilter ? [gradeLevelIdFilter] : undefined,
      subject_ids: subjectIdFilter ? [subjectIdFilter] : undefined,
      standard_collection_id: standardCollectionIdFilter || undefined,
      term: search,
      page: 1,
      per_page: 10,
    });
  };

  const handleSkillsSearch = (search: string) => {
    setSkillsSearchQuery(search);
    executeFetchSkills.current({
      limit: 10,
      offset: 0,
      query: search,
    });
  };

  const prepareDataForMutation = (
    data: CreateQuestionFormDataType
  ):
    | IMutateTermDefinitionArgs
    | IMutateTextResponseArgs
    | IMutateNumberResponseArgs
    | IMutateMultipleChoiceArgs
    | IMutateDrawArgs
    | IMutateDiagramArgs
    | IMutateClassifyArgs => {
    const practice_set_item = itemFetch.data?.practice_set_item;

    const standardsData = data.standard as CreateStandardTabForm;
    const feedbackData = data.feedback as CreateFeedbackTabForm;
    const internalData = (data.internal as CreateInternalTabForm) || {};

    const termData = data.term as CreateTermTabForm;
    const textResponseData = data.textResponse as CreateTextResponseTabForm;
    const numberResponseData =
      data.numberResponse as CreateNumberResponseTabForm;
    const multipleChoiceData =
      data.multipleChoice as CreateMultipleChoiceTabForm;
    const drawData = data.draw as CreateDrawTabForm;
    const diagramData = data.diagram as CreateDiagramTabForm;
    const classifyData = data.classify as CreateClassificationTabForm;

    const responseData = {} as IMutateTermDefinitionArgs &
      IMutateTextResponseArgs &
      IMutateNumberResponseArgs &
      IMutateMultipleChoiceArgs &
      IMutateDrawArgs &
      IMutateDiagramArgs &
      IMutateClassifyArgs;
    switch (typedQuestionType) {
      case QuestionType.TermDefinition:
        responseData.term_definition_id =
          practice_set_item?.term_definition?.id || undefined;
        responseData.term = termData.term;
        responseData.definition = termData.definition;
        responseData.is_higher_order_thinking =
          termData.requiresHigherOrderThinking;
        responseData.image_url = termData.imageUrl || undefined;
        responseData.image_alt_text = termData.imageAltText || undefined;
        responseData.audio_url = termData.audioUrl || undefined;
        responseData.video_url = termData.videoUrl || undefined;
        responseData.alternate_text_responses = termData.alternateSpellings
          .filter((alternate_text) => alternate_text)
          .map((alternate_text) => ({
            alternate_text,
          }));
        break;
      case QuestionType.TextResponse:
        responseData.text_response_id =
          practice_set_item?.text_response?.id || undefined;
        responseData.prompt = JSON.stringify(textResponseData.prompt);
        responseData.correct_response = textResponseData.correctResponse;
        responseData.enable_partial_credit =
          textResponseData.enablePartialCredit;
        responseData.is_higher_order_thinking =
          textResponseData.requiresHigherOrderThinking;
        responseData.image_url = textResponseData.imageUrl || undefined;
        responseData.image_alt_text =
          textResponseData.imageAltText || undefined;
        responseData.audio_url = textResponseData.audioUrl || undefined;
        responseData.video_url = textResponseData.videoUrl || undefined;
        responseData.alternate_text_responses =
          textResponseData.alternateCorrectResponses
            .filter((alternate_text) => alternate_text)
            .map((alternate_text) => ({
              alternate_text: alternate_text.response,
            }));
        break;
      case QuestionType.NumberResponse:
        responseData.number_response_id =
          practice_set_item?.number_response?.id || undefined;
        responseData.prompt = JSON.stringify(numberResponseData.prompt);
        responseData.correct_response = numberResponseData.correctResponse;
        responseData.prefix = JSON.stringify(numberResponseData.prefix);
        responseData.suffix = JSON.stringify(numberResponseData.suffix);
        responseData.is_higher_order_thinking =
          numberResponseData.requiresHigherOrderThinking;
        responseData.image_url = numberResponseData.imageUrl || undefined;
        responseData.image_alt_text =
          numberResponseData.imageAltText || undefined;
        responseData.audio_url = numberResponseData.audioUrl || undefined;
        responseData.video_url = numberResponseData.videoUrl || undefined;
        responseData.alternate_text_responses =
          numberResponseData.alternateCorrectResponses
            .filter((alternate_text) => alternate_text)
            .map((alternate_text) => ({
              alternate_text: alternate_text.response,
            }));
        break;
      case QuestionType.MultipleChoice:
      case QuestionType.MultipleSelect: {
        const imageMode = multipleChoiceData.responses.some(
          (response) => !!response.imageUrl
        );
        responseData.multiple_choice_id =
          practice_set_item?.multiple_choice?.id || undefined;
        responseData.multiple_select_id =
          practice_set_item?.multiple_select?.id || undefined;
        responseData.prompt = JSON.stringify(multipleChoiceData.prompt);
        responseData.choices = multipleChoiceData.responses.map((response) => ({
          text: imageMode ? "" : JSON.stringify(response.value),
          is_correct: response.isCorrect,
          image_url: response.imageUrl,
          image_alt_text: response.imageAltText,
        }));
        responseData.image_mode = imageMode;
        responseData.questionType = multipleChoiceData.questionType;
        responseData.is_higher_order_thinking =
          multipleChoiceData.requiresHigherOrderThinking;
        responseData.image_url = multipleChoiceData.imageUrl || undefined;
        responseData.image_alt_text =
          multipleChoiceData.imageAltText || undefined;
        responseData.audio_url = multipleChoiceData.audioUrl || undefined;
        responseData.video_url = multipleChoiceData.videoUrl || undefined;
        break;
      }
      case QuestionType.Draw:
        responseData.draw_id = practice_set_item?.draw?.id || undefined;
        responseData.prompt = JSON.stringify(drawData.prompt);
        responseData.is_higher_order_thinking =
          drawData.requiresHigherOrderThinking;
        responseData.image_url = drawData.imageUrl || undefined;
        responseData.image_alt_text = drawData.imageAltText || undefined;
        responseData.audio_url = drawData.audioUrl || undefined;
        responseData.video_url = drawData.videoUrl || undefined;
        responseData.background_image_url =
          drawData.backgroundImageUrl || undefined;
        break;
      case QuestionType.Diagram:
        responseData.diagram_id = practice_set_item?.diagram?.id || undefined;
        responseData.prompt = JSON.stringify(diagramData.prompt);
        responseData.diagram_labels = diagramData.labels.map(
          ({ label, x_offset_ratio, y_offset_ratio }) => ({
            label,
            x_offset_ratio,
            y_offset_ratio,
          })
        );
        responseData.is_higher_order_thinking =
          diagramData.requiresHigherOrderThinking;
        responseData.image_url = diagramData.imageUrl || undefined;
        responseData.image_alt_text = diagramData.imageAltText || undefined;
        break;
      case QuestionType.Classify: {
        const imageMode = classifyData.categories.some((category) =>
          category.choices.some((choice) => !!choice.image_url)
        );
        responseData.classify_id = practice_set_item?.classify?.id || undefined;
        responseData.prompt = JSON.stringify(classifyData.prompt);
        responseData.categories = classifyData.categories.map((category) => {
          return {
            ...category,
            id: "0",
            text: JSON.stringify(category.text),
            choices: category.choices.map((choice) => {
              return {
                ...choice,
                id: "0",
                text: imageMode ? "" : JSON.stringify(choice.text),
              };
            }),
          };
        });
        responseData.image_mode = imageMode;
        responseData.is_higher_order_thinking =
          classifyData.requiresHigherOrderThinking;
        responseData.image_url = classifyData.imageUrl || undefined;
        responseData.image_alt_text = classifyData.imageAltText || undefined;
        break;
      }
    }

    const { standards } = standardsData;
    const {
      feedback,
      imageUrl: feedbackImageUrl,
      imageAltText: feedbackImageAltText,
    } = feedbackData;
    const { cncCode, isCertified, skill, isPremium } = internalData;

    responseData.id = itemId;
    responseData.practice_set_id = practiceSet.id;
    responseData.feedback = JSON.stringify(feedback);
    responseData.feedback_image_url = feedbackImageUrl || undefined;
    responseData.feedback_image_alt_text = feedbackImageAltText || undefined;
    responseData.standards = standards.map((s) => ({ id: s.id }));
    responseData.cnc_code = cncCode;
    responseData.is_certified = isCertified;
    responseData.is_premium = isPremium;
    responseData.skill_id = skill?.id;
    responseData.order = practice_set_item?.order || undefined;

    return responseData;
  };

  const handleSaveAndClose = (data: CreateQuestionFormDataType) => {
    if (itemId && !itemFetch.data) return;

    const args = prepareDataForMutation(data);
    switch (typedQuestionType) {
      case QuestionType.TermDefinition:
        termDefinitionMutation.mutate(args as IMutateTermDefinitionArgs);
        break;
      case QuestionType.TextResponse:
        textResponseMutation.mutate(args as IMutateTextResponseArgs);
        break;
      case QuestionType.NumberResponse:
        numberResponseMutation.mutate(args as IMutateNumberResponseArgs);
        break;
      case QuestionType.MultipleChoice:
        multipleChoiceMutation.mutate(args as IMutateMultipleChoiceArgs);
        break;
      case QuestionType.MultipleSelect:
        multipleSelectMutation.mutate(args as IMutateMultipleChoiceArgs);
        break;
      case QuestionType.Draw:
        drawMutation.mutate(args as IMutateDrawArgs);
        break;
      case QuestionType.Diagram:
        diagramMutation.mutate(args as IMutateDiagramArgs);
        break;
      case QuestionType.Classify:
        classifyMutation.mutate(args as IMutateClassifyArgs);
        break;
    }
  };

  const isSubmitting = (() => {
    switch (typedQuestionType) {
      case QuestionType.TermDefinition:
        return termDefinitionMutation.isLoading;
      case QuestionType.TextResponse:
        return textResponseMutation.isLoading;
      case QuestionType.NumberResponse:
        return numberResponseMutation.isLoading;
      case QuestionType.MultipleChoice:
        return multipleChoiceMutation.isLoading;
      case QuestionType.MultipleSelect:
        return multipleSelectMutation.isLoading;
      case QuestionType.Draw:
        return drawMutation.isLoading;
      case QuestionType.Diagram:
        return diagramMutation.isLoading;
      case QuestionType.Classify:
        return classifyMutation.isLoading;
      default:
        return false;
    }
  })();

  if (itemId && !practiceSetItem) return <></>;

  return (
    <QuestionFlyout
      isSubmitting={isSubmitting}
      isLoadingStandards={fetchStandards.isLoading}
      isOpen={isItemDetailOpen}
      isInternalUser={isInternalUser}
      isImageMode={isImageMode}
      flyoutType={typedQuestionType}
      grades={gradeLevelOptions}
      gradeLevelIdFilter={gradeLevelIdFilter}
      subjects={subjectOptions}
      subjectIdFilter={subjectIdFilter}
      states={regionOptions}
      regionFilter={regionFilter}
      defaultValue={practiceSetItem}
      standards={fetchStandards.data?.standards}
      skills={fetchSkills.data?.skills}
      // These dictate the "alternate question types" options -- i.e. the types of questions that can be generated.
      // TODO: add support for specifying which question types can be generated
      questionTypes={[]}
      handleChangeFilter={handleStandardsFilterChange}
      handleSaveAndClose={handleSaveAndClose}
      handleStandardsSearch={handleStandardsSearch}
      handleSkillsSearch={handleSkillsSearch}
      handleClose={onItemDetailClose}
      showPremiumMarker={showPremiumMarker}
      isGeneratingFeedback={isGeneratingFeedback}
      handleGenerateFeedback={handleGenerateFeedback}
      generatedFeedback={generatedFeedback}
    />
  );
};

export default PracticeSetItemFlyout;
